// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-index-de-js": () => import("./../../../src/pages/about/index.de.js" /* webpackChunkName: "component---src-pages-about-index-de-js" */),
  "component---src-pages-about-index-en-js": () => import("./../../../src/pages/about/index.en.js" /* webpackChunkName: "component---src-pages-about-index-en-js" */),
  "component---src-pages-aeb-index-de-js": () => import("./../../../src/pages/aeb/index.de.js" /* webpackChunkName: "component---src-pages-aeb-index-de-js" */),
  "component---src-pages-aeb-index-en-js": () => import("./../../../src/pages/aeb/index.en.js" /* webpackChunkName: "component---src-pages-aeb-index-en-js" */),
  "component---src-pages-agb-index-de-js": () => import("./../../../src/pages/agb/index.de.js" /* webpackChunkName: "component---src-pages-agb-index-de-js" */),
  "component---src-pages-agb-index-en-js": () => import("./../../../src/pages/agb/index.en.js" /* webpackChunkName: "component---src-pages-agb-index-en-js" */),
  "component---src-pages-contact-index-de-js": () => import("./../../../src/pages/contact/index.de.js" /* webpackChunkName: "component---src-pages-contact-index-de-js" */),
  "component---src-pages-contact-index-en-js": () => import("./../../../src/pages/contact/index.en.js" /* webpackChunkName: "component---src-pages-contact-index-en-js" */),
  "component---src-pages-imprint-index-de-js": () => import("./../../../src/pages/imprint/index.de.js" /* webpackChunkName: "component---src-pages-imprint-index-de-js" */),
  "component---src-pages-imprint-index-en-js": () => import("./../../../src/pages/imprint/index.en.js" /* webpackChunkName: "component---src-pages-imprint-index-en-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-news-index-de-js": () => import("./../../../src/pages/news/index.de.js" /* webpackChunkName: "component---src-pages-news-index-de-js" */),
  "component---src-pages-news-index-en-js": () => import("./../../../src/pages/news/index.en.js" /* webpackChunkName: "component---src-pages-news-index-en-js" */),
  "component---src-pages-press-index-de-js": () => import("./../../../src/pages/press/index.de.js" /* webpackChunkName: "component---src-pages-press-index-de-js" */),
  "component---src-pages-press-index-en-js": () => import("./../../../src/pages/press/index.en.js" /* webpackChunkName: "component---src-pages-press-index-en-js" */),
  "component---src-pages-privacy-index-de-js": () => import("./../../../src/pages/privacy/index.de.js" /* webpackChunkName: "component---src-pages-privacy-index-de-js" */),
  "component---src-pages-privacy-index-en-js": () => import("./../../../src/pages/privacy/index.en.js" /* webpackChunkName: "component---src-pages-privacy-index-en-js" */),
  "component---src-templates-news-article-page-js": () => import("./../../../src/templates/news-article-page.js" /* webpackChunkName: "component---src-templates-news-article-page-js" */),
  "component---src-templates-what-we-do-page-js": () => import("./../../../src/templates/what-we-do-page.js" /* webpackChunkName: "component---src-templates-what-we-do-page-js" */)
}

