import "@formatjs/intl-datetimeformat/polyfill"
import de from "./de.json"
import "@formatjs/intl-datetimeformat/locale-data/de"
import en from "./en.json"
import "@formatjs/intl-datetimeformat/locale-data/en"

const loadLocaleData = locale => {
  switch (locale) {
    case "de":
      return de
    case "en":
    default:
      return en
  }
}

export default loadLocaleData
