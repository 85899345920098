import "./src/styles/global.css"
import React from "react"
import LocationContext from "./src/context/location-context"
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper"
import { IntlProvider } from "react-intl"
import loadLocaleData from "./src/lang/utils"

SwiperCore.use([Pagination, Navigation, Autoplay])

export const wrapPageElement = ({
  element,
  props: { location, pageContext },
}) => {
  return (
    <LocationContext.Provider value={location}>
      <IntlProvider
        locale={pageContext.langKey || "en"}
        defaultLocale="en"
        messages={loadLocaleData(pageContext.langKey || "en")}
      >
        {element}
      </IntlProvider>
    </LocationContext.Provider>
  )
}
